import vSelect from 'vue-select'
import { caddyCalendar } from '@/api/caddy-calendar'
import 'devextreme/dist/css/dx.light.css'
import { caddyEvaluation } from '@/api/caddy-evaluation'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,  S, R, P
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
export default {
  name: 'QuickAddPoint',
  components: {
    vSelect,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    ShortKey
  },
  props: [
    'date',
    'listCaddy',
    'listScore',
  ],
  data() {
    return {
      keyWordSearch: '',
      isOpen: false,
      VoteClassId: null,
      CaddyVote: [],
      listCaddySelect: [],
      listCaddySelectString: '',
      CaddySelect: [],
      caddyId: null,
      isOpen: false,
      itemTableCaddySelect: [],
      itemTableCaddySelectTemp: [],
      currentPage: 1,
      perPage: 50,
      optionPerPage: [10, 50, 100],
      configTable: [
        {
          caption: 'Tổ caddy',
          field: 'CaddyGroupName',
          isShow: true,
          alignment: 'center',
          width: '25%',
        },
        {
          caption: 'Mã caddy',
          field: 'CaddyCode',
          isShow: true,
          alignment: 'center',
          width: '25%',
        },
        {
          caption: 'Tên caddy',
          field: 'CaddyName',
          isShow: true,
          alignment: 'center',
          width: '25%',
        },
        {
          caption: 'Số phiếu',
          field: 'Point',
          isShow: true,
          alignment: 'center',
          cellTemplate: 'Point',
          width: '20%',
        },
        {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'center',
          width: '5%',
        }
      ],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      allChildComponentClosed: [],
      NoteInput: null,
      disableButton: false
    }
  },
  watch: {
    // listCaddy(val) {
    //   this.listCaddySelect = this.listCaddy.map(x => ({
    //     id: x.Id,
    //     label: `${x.CaddyCode} - ${x.CaddyName}`,
    //   }))
    // },
    listScore(val) {
      this.VoteClassId = val[0].Id
    },
    isOpen(value) {
      this.$emit('is-open', { ComponentId: 'modal-quick-add-point', IsActive: value })
    }
  },
  methods: {
    hideSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'modal-quick-add-point')
    },
    addCaddyCode() {
      var dataCaddySelected = this.listCaddy.find(x => x.CaddyCode === this.CaddySelect[0].toUpperCase())
      if(dataCaddySelected) {
        this.listCaddySelect.push(this.CaddySelect[0])
        this.listCaddySelectString = this.listCaddySelect.toString()
        if(this.itemTableCaddySelectTemp.find(x => x.Id === dataCaddySelected.Id)) {
          this.itemTableCaddySelectTemp.forEach(y => {
            if(y.Id === dataCaddySelected.Id) {
              y.Point = y.Point + 1
            }
          }) 
        } else {
          dataCaddySelected.Point = 1
          this.itemTableCaddySelectTemp.push(dataCaddySelected)
          this.itemTableCaddySelectTemp = JSON.parse(JSON.stringify(this.itemTableCaddySelectTemp)) 
        }
        if(this.itemTableCaddySelect.find(x => x.Id === dataCaddySelected.Id)) {
          this.itemTableCaddySelect.forEach(y => {
            if(y.Id === dataCaddySelected.Id) {
              y.Point = y.Point + 1
            }
          }) 
        } else {
          dataCaddySelected.Point = 1
          this.itemTableCaddySelect.push(dataCaddySelected)
          this.itemTableCaddySelect = JSON.parse(JSON.stringify(this.itemTableCaddySelect)) 
        }
      } else {
        this.showToast('error', `Mã caddy ${this.CaddySelect[0]} không phù hợp`)
      }
      this.CaddySelect = []
      setTimeout(() => {
        this.$refs.CaddySelect.$refs.search.focus()
      },50)
    },
    deleteCaddySelect(data) {
      this.itemTableCaddySelect.forEach((x,index) => {
        if(x.Id === data.Id) {
          this.itemTableCaddySelect.splice(index,1)
        }
      })
      this.itemTableCaddySelectTemp.forEach((x,index) => {
        if(x.Id === data.Id) {
          this.itemTableCaddySelect.splice(index,1)
        }
      })
       this.listCaddySelect = this.listCaddySelect.filter(x => x.toUpperCase() !== data.CaddyCode.toUpperCase())
       this.listCaddySelectString = this.listCaddySelect.toString()
    },
    async updatePointCaddy() {
      this.disableButton = true
      var dataBodyCaddyId = []
      this.itemTableCaddySelectTemp.forEach(x => {
        dataBodyCaddyId.push({
          CaddyId: x.Id,
          NumberOfVote: x.Point
        })
      })
      const body = {
        DateVote: this.date,
        VoteClassId: this.VoteClassId,
        CaddyVote: dataBodyCaddyId,
        Note: this.NoteInput
      }
      await caddyEvaluation.api_ICAE01A(body).then(res => {
        this.showResToast(res)
        if(res.Status === '200') {
          this.$emit('event')
          this.listCaddySelect = []
          this.listCaddySelectString = this.listCaddySelect.toString()
          this.itemTableCaddySelectTemp = []
          this.itemTableCaddySelect = []
          this.NoteInput = null
        }
        this.disableButton = false
      })
    },
    searchCaddySelect() {
      var data = []
      if(this.keyWordSearch) {
        this.itemTableCaddySelectTemp.forEach(x => {
          if(x.CaddyCode.toUpperCase().includes(this.keyWordSearch.toUpperCase()) || x.CaddyName.toUpperCase().includes(this.keyWordSearch.toUpperCase())) {
            data.push(x)
          }
        })
        this.itemTableCaddySelect =  JSON.parse(JSON.stringify(data)) 
      } else {
        this.itemTableCaddySelect =  JSON.parse(JSON.stringify(this.itemTableCaddySelectTemp)) 
      }
    },
    changePointCaddy(data) {
      this.itemTableCaddySelectTemp.forEach(x => {
        if(data.Id === x.Id) {
          x.Point = Number(data.Point)
        }
      })
      this.itemTableCaddySelect.forEach(y => {
        y.Point = Number(y.Point)
      })
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        if(!this.disableButton) {
          this.updatePointCaddy()
        }
      } 
    },
  },
}
