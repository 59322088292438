/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
    ROOT,
    API_RCAE01,
    API_UCAE01,
    API_RCAV01,
    API_UCAV01,
    API_ICAE01A
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListCaddyEvalue(data) {
    return fetch(ROOT + API_RCAE01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function updateCaddyEvaluetion(data) {
    return fetch(ROOT + API_UCAE01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function get_API_RCAV01(data) {
    return fetch(ROOT + API_RCAV01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function update_UCAV01(data) {
    return fetch(ROOT + API_UCAV01, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}

function api_ICAE01A(data) {
    return fetch(ROOT + API_ICAE01A, requestOptions.postBody(data))
        .then(handleResponse)
        .then(data => data)
}
export const caddyEvaluation = {
    getListCaddyEvalue,
    updateCaddyEvaluetion,
    get_API_RCAV01,
    update_UCAV01,
    api_ICAE01A
}
