import {
  BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BCard, BDropdown, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay, BSidebar, VBToggle, BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ezToast } from '@core/utils/utils'

import flatPickr from 'vue-flatpickr-component'
import dayjs from 'dayjs'
import { caddyEvaluation } from '@/api/caddy-evaluation'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import { commonServices } from '@/api/common-services'
import VSwatches from 'vue-swatches'
import QuickAddPoint from './QuickAddPoint/QuickAddPoint.vue'
import 'vue-swatches/dist/vue-swatches.css'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,  S, R, P
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
export default {
  name: 'CaddyEvaluation',
  components: {
    QuickAddPoint,
    ToastificationContent,
    BForm,
    BSidebar,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    flatPickr,
    'delete-dialog': DeleteDialog,
    VSwatches,
    ShortKey
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      userData: JSON.parse(localStorage.getItem('userData')),
      humanDate: null,
      caddyGroupSelected: null,
      listSex: [],
      listTypeOfContract: [],
      listCaddyPosition: [],
      listMaritalStatus: [],
      listCaddyGroup: [],
      listCaddyStatus: [
        {
          KeyValue: true,
          KeyName: 'Ngừng hoạt động',
        },
        {
          KeyValue: false,
          KeyName: 'Đang hoạt động',
        },
      ],
      listCaddy: [],
      listCourse: [],
      bloodList: ['O', 'A', 'B', 'AB'],
      listClotherSize: ['S', 'M', 'L', 'XL', 'XXL'],
      isAddNewCaddyGroup: false,
      addNewCaddyGroupName: null,
      isEditCaddyGroup: false,
      editCaddyGroupName: null,
      editCaddyGroupId: null,
      detailCaddy: {},
      searchObj: {
        keyword: null,
        age: null,
        courseId: null,
        sex: null,
      },
      preview: null, // MB
      search: {
        KeyWord: null,
        EvaluationDate: this.getUTC('start', 'day'),
        EvaluationEndDate: this.getUTC('start', 'day'),
        // CaddyGroupId: [],
        BagtagCode: null,
      },
      data: {},
      detail: null,
      selectCaddy: [],
      checkDateDetail: false,
      isEdit: false,
      loading: true,
      slideBar_caddy: false,
      statusFilter: 'all',
      //
      listScores: [],
      listScoresUpdate: [],
      slideBar_detail_update: false,
      CaddyEvaluationVote: [],
      caddyEdit: {
        CaddyEvaluation: [],
        note: null,
      },
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, S, R, P],
      allChildComponentClosed: [
        {
          ComponentId: 'modal-quick-add-point',
          IsActive: false,
        }
      ],
    }
  },
  created() {
    this.getListCaddyEvalue()
    // this.getListCaddyCalendar_RCAC01_AC1();
    // this.callApi_RCA03();
    this.getGroupCaddy()
    this.getList_RCAV01()
  },
  computed: {
    previewImage() {
      return this.preview
    },
  },
  methods: {
    _dayjs: dayjs,

    openUpdateDetail(data, date) {
      this.listScoresUpdate.forEach(x => {
        if(date.CaddyEvaluationVote.find(y => y.VoteClassId == x.Id)) {
          x.NumberOfVote = date.CaddyEvaluationVote.find(y => y.VoteClassId == x.Id).NumberOfVote
        } else {
          x.NumberOfVote = 0
        }
      })
      // this.CaddyEvaluationVote = JSON.parse(JSON.stringify(caddy.CaddyEvaluation[0].CaddyEvaluationVote))
      this.caddyEdit = JSON.parse(JSON.stringify({
        ...date,
        CaddyId: data.Id
      }))
      this.slideBar_detail_update = true
    },
    async updateCaddyEvaluetion() {
      const arr = []
      this.listScoresUpdate.forEach(e => {
        if(+e.NumberOfVote > 0) {
          arr.push({
            VoteClassId: e.Id,
            NumberOfVote: +e.NumberOfVote
          })
        }
      })
      const CaddyEvaluation = {
        CaddyEvaluation: {
          CaddyId: this.caddyEdit.CaddyId,
          EvaluationDate: this.caddyEdit.EvaluationDate,
          Note: this.caddyEdit.Note,
          CaddyEvaluationVote: [...arr],
        }
      }
      await caddyEvaluation
        .updateCaddyEvaluetion(CaddyEvaluation)
        .then(res => {
          if(res.Status == 200) {
            this.listScoresUpdate.map(x => x.NumberOfVote = 0)
            this.slideBar_detail_update = false
            this.getListCaddyEvalue()
          }
          this.showResToast(res)
          
        })
    },
    getListCaddyEvalue() {
      this.loading = true
      this.listCaddy = []
      caddyEvaluation
        .getListCaddyEvalue(this.search)
        .then(res => {
          if (res) {
            this.data = { ...res.Data }
            if (res.Data && res.Data.CaddyGroup && res.Data.CaddyGroup.length > 0) {
              res.Data.CaddyGroup.forEach(e => {
                e.Caddy.forEach(cd => {
                  this.listCaddy.push({ ...cd, CaddyGroupName: e.CaddyGroupName, IdGroup: e.Id })
                })
              })
            }
            this.loading = false
          }
        })
    },

    getList_RCAV01() {
      caddyEvaluation
        .get_API_RCAV01()
        .then(res => {
          if (res) {
            this.listScoresUpdate = []
            this.listScores = res.Data.CaddyVoteClass
            this.listScores.forEach(x => {
              this.listScoresUpdate.push({
                Id : x.Id,
                Score : x.Score,
                Color : x.Color,
                VoteClass : x.VoteClass,
                NumberOfVote: 0
              })
            })
          }
        })
    },

    async getListCaddyCalendar_RCAC01_AC1() {
      // Lấy danh sách tổ caddy
      await caddyEvaluation
        .getListCaddyCalendar(this.search)
        .then(res => {
          if (res) {
            this.data = { ...res.Data }
            this.loading = false
          }
        })
    },
    getGroupCaddy() {
      commonServices
        .getCommon({ KeyGroup: 'CADDY_PO' })
        .then(res => {
          if (res) {
            this.listCaddyPosition = res.Data.filter(x => x.LangId == this.currentLang)
          }
        })
    },
    openPopupConfig() {
      this.getList_RCAV01()
      this.slideBar_caddy = true
    },
    // config

    addScores() {
      this.listScores.push({
        VoteClass: null,
        Color: 'red',
        Score: 1,
        Id: -1,
      })
    },
    removeScores(index) {
      this.listScores.splice(index, 1)
    },
    update_UCAV01() {
      const body = {
        CaddyVoteClass: [],
      }
      this.listScores.forEach(element => {
        body.CaddyVoteClass.push({
          VoteClass: element.VoteClass,
          Color: element.Color,
          Score: +element.Score,
          Id: element.Id,
        })
      })
      caddyEvaluation
        .update_UCAV01(body)
        .then(res => {
          if (res) {
            this.showResToast(res)
            this.slideBar_caddy = false
          }
        })
    },
    openModalQuickAddPoint() {
      this.$root.$emit('bv::toggle::collapse', 'modal-quick-add-point')
    },
    handleEvent() {
      this.getListCaddyEvalue()
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    onTriggeredEventHandler(payload) {
       
    }
  },
}
